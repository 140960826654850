/* Meter.svelte generated by Svelte v3.18.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	svg_element
} from "svelte/internal";

import { line, curveCatmullRom } from "d3-shape";
import { freq, lastHundredFreqs } from "./stores";

function add_css() {
	var style = element("style");
	style.id = "svelte-udyu3q-style";
	style.textContent = "svg.svelte-udyu3q{width:100%;height:40vh}.pip.svelte-udyu3q{stroke:rgb(0, 0, 0);stroke-width:0.4}#needle.svelte-udyu3q{stroke:red;stroke-width:0.5;will-change:transform}#line.svelte-udyu3q{stroke:white;stroke-width:0.4}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	child_ctx[9] = i;
	return child_ctx;
}

// (42:2) {#each pips as pip, index}
function create_each_block(ctx) {
	let line_1;
	let line_1_x__value;
	let line_1_x__value_1;
	let line_1_y__value;

	return {
		c() {
			line_1 = svg_element("line");
			attr(line_1, "class", "pip svelte-udyu3q");
			attr(line_1, "x1", line_1_x__value = "" + (/*index*/ ctx[9] * 5 + "%"));
			attr(line_1, "y1", "100%");
			attr(line_1, "x2", line_1_x__value_1 = "" + (/*index*/ ctx[9] * 5 + "%"));
			attr(line_1, "y2", line_1_y__value = "" + (80 - /*pip*/ ctx[7] + "%"));
		},
		m(target, anchor) {
			insert(target, line_1, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(line_1);
		}
	};
}

function create_fragment(ctx) {
	let svg;
	let path;
	let line_1;
	let each_value = /*pips*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			line_1 = svg_element("line");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(path, "id", "line");
			attr(path, "d", /*svgline*/ ctx[0]);
			attr(path, "fill", "none");
			attr(path, "class", "svelte-udyu3q");
			attr(line_1, "id", "needle");
			attr(line_1, "x1", "0");
			attr(line_1, "x2", "0");
			attr(line_1, "y1", "100%");
			attr(line_1, "y2", "35%");
			set_style(line_1, "transform", "translate(" + /*percent*/ ctx[1] + "%, 0)");
			attr(line_1, "class", "svelte-udyu3q");
			attr(svg, "id", "graph");
			attr(svg, "viewBox", "0 0 100 100");
			attr(svg, "preserveAspectRatio", "none");
			attr(svg, "class", "svelte-udyu3q");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
			append(svg, line_1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(svg, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*svgline*/ 1) {
				attr(path, "d", /*svgline*/ ctx[0]);
			}

			if (dirty & /*percent*/ 2) {
				set_style(line_1, "transform", "translate(" + /*percent*/ ctx[1] + "%, 0)");
			}

			if (dirty & /*pips*/ 4) {
				each_value = /*pips*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(svg, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $freq;
	let $lastHundredFreqs;
	component_subscribe($$self, freq, $$value => $$invalidate(4, $freq = $$value));
	component_subscribe($$self, lastHundredFreqs, $$value => $$invalidate(5, $lastHundredFreqs = $$value));
	const fn = line().curve(curveCatmullRom);
	let graphpoints;
	let svgline;
	const pips = Array(21).fill(0).map((_x, i) => i % 4 === 0 ? 30 : 5);
	let percent;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$freq*/ 16) {
			$: $$invalidate(1, percent = $freq / 3951 * 100);
		}

		if ($$self.$$.dirty & /*$lastHundredFreqs*/ 32) {
			$: {
				let newPoints = [];
				let i = 0;

				for (const y of $lastHundredFreqs) {
					if (y !== null) {
						newPoints.push([i, 100 - y / 3951 * 100]);
					}

					i++;
				}

				$$invalidate(3, graphpoints = newPoints);
			}
		}

		if ($$self.$$.dirty & /*graphpoints*/ 8) {
			$: {
				$$invalidate(0, svgline = fn(graphpoints));
			}
		}
	};

	return [svgline, percent, pips];
}

class Meter extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-udyu3q-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Meter;